// Styles for btns
.save-btn {
  width: 54px;
  height: 40px;
  background-image: url('/assets/images/save-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;

  &:disabled {
    background-image: url('/assets/images/save-bg-disabled.png');
  }
}

.close-btn {
  width: 54px;
  height: 40px;
  background-image: url('/assets/images/close-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;

  &:disabled {
    background-image: url('/assets/images/close-bg-disabled.png');
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 20px;
  line-height: 25px;
  font-family: 'CeraPro Bold';
  color: var(--white);
  text-decoration: none;

  &._fz16 {
    font-size: 16px;
    line-height: 20px;
  }

  &._white {
    color: var(--dark2);
  }

  &._small {
    width: 139px;

    &._red {
      background-image: url('/assets/images/red-small-btn.png');
    }

    &._white {
      background-image: url('/assets/images/white-small-btn.png');
    }

    &:disabled, &._black {
      background-image: url('/assets/images/black-small-btn.png');
    }
  }

  &._medium {
    width: 205px;

    &._red {
      background-image: url('/assets/images/red-medium-btn.png');
    }

    &._white {
      background-image: url('/assets/images/white-medium-btn.png');
    }

    &:disabled, &._black {
      background-image: url('/assets/images/black-medium-btn.png');
    }
  }

  &._large {
    width: 252px;
    height: 42px;

    &._red {
      background-image: url('/assets/images/red-large-btn.png');
    }

    &._white {
      background-image: url('/assets/images/white-large-btn.png');
    }

    &:disabled, &._black {
      background-image: url('/assets/images/black-large-btn.png');
    }
  }

  &._uppercase {
    text-transform: uppercase;
  }
}

// Styles for slider btns

.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  background-image: url('/assets/images/slider-arrow-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &:disabled {
    background-image: url('/assets/images/slider-arrow-bg-disabled.png');
  }

  &._prev {
    transform: scale(-1, 1);
  }
}
