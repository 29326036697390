@use "normalize.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  touch-action: manipulation;
}

body {
  font-family: 'CeraPro Medium', Fallback, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

button {
  font-family: 'CeraPro Medium', Fallback, sans-serif;
  background: transparent;
  border: none;
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* задний фон под игрой, чтобы не было белого экрана на планшете и десктопе. надо будет потом заменить на актуальный для этой игры! */
  background-image: url('/assets/images/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  width: 100%;
  height: 100%;
  //min-height: 640px;
  background-color: #ffffff;
  position: relative;
}

@media only screen and (min-width: 585px) {
  .wrapper {
    position: relative;
  }

  .main {
    border-radius: 30px;
    width: 330px;
    height: 710px;
    padding: 7px 0;
  }

  .main::after {
    content: '';
    width: 362px;
    height: 738px;
    position: absolute;
    top: -14px;
    left: -14px;
    background-image: url('/assets/images/phone.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    z-index: 99;
  }
}

// styles for checkbox
.checkbox {
  padding-left: 30px;
  position: relative;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    display: block;

    p {
      font-family: 'CeraPro Medium';
      color: #fff;
      font-size: 8px;
      line-height: 12px;
      font-weight: 500;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      padding: 6px;
      display: block;
      cursor: pointer;
      position: absolute;
      left: -25px;
      top: -2px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -21px;
      top: 2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: linear-gradient(20.64deg, #DFC9A2 -55.46%, #EB1001 70.59%);
      visibility: hidden;
      opacity: 0;
      transition: .3s;
    }
  }

  input:checked + label:after {
    visibility: visible;
    opacity: 1;
    transition: .3s;
  }
}

// styles for .page
.page {
  width: 100%;
  height: 100%;
  background-image: url('/assets/images/simple-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/grain.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: .5;
  }

  @media only screen and (min-width: 585px) {
    border-radius: 30px;
    overflow: hidden;

    .onboarding__slider {
      margin-top: 65px;
    }
  }

  &__cloud {
    position: absolute;
    height: 17px;
    left: -20px;
    top: 10px;
    width: 95px;
  }

  &__smallDecor {
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__busWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 12px;
    padding-right: 24px;
    margin-top: 24px;

    @media only screen and (min-width: 585px) {
      margin-top: 65px;
    }
  }

  &__bus {
    width: 115px;
    height: 50px;
    margin-bottom: -4px;
    z-index: 1;
  }

  &__pointer {
    display: flex;
    justify-content: center;
    padding-left: 8px;
    padding-right: 20px;
    padding-top: 8px;
    width: 108px;
    height: 51px;
    background-image: url('/assets/images/pointer.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;

    span {
      font-family: 'CeraPro Bold';
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__dark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 29.63%, rgba(0, 0, 0, 0.24) 50.14%, rgba(21, 29, 35, 0.91) 98.28%), #333B42;

    @media only screen and (min-width: 585px) {
      padding-bottom: 40px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-image: url('/assets/images/round-decor.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &._padding12 {
      padding: 0 12px;
    }

    &._background {
      padding-top: 24px;
      background: linear-gradient(180deg, rgba(217, 217, 217, 0.13) 0%, rgba(115, 115, 115, 0) 100%);
    }
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
