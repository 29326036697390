@font-face {
  font-family: 'CeraPro Light';
  src: url('../../assets/fonts/CeraPro-Light.ttf') format('truetype'),
  url('../../assets/fonts/CeraPro-Light.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CeraPro Regular';
  src: url('../../assets/fonts/CeraPro-Regular.ttf') format('truetype'),
  url('../../assets/fonts/CeraPro-Regular.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CeraPro Medium';
  src: url('../../assets/fonts/CeraPro-Medium.ttf') format('truetype'),
  url('../../assets/fonts/CeraPro-Medium.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CeraPro Bold';
  src: url('../../assets/fonts/CeraPro-Bold.ttf') format('truetype'),
  url('../../assets/fonts/CeraPro-Bold.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
