.modal-component{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__description{}

  &__btn-wrapper{
    display: flex;
    gap: 26px;
    justify-content: center;
  }
}
